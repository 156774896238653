.toaster {
  position: fixed !important;
  width: 96%;
  left: 2%;
  bottom: 5.5em;
}

@media screen and (min-width: 768px) {
  .toaster {
    width: initial;
    left: initial;
    right: 10em;
    bottom: 4em;
  }
}