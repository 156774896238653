/* BluePrint override styles, reset and/or normalize or any styles should apply across the website.
Don't put every style here, Prefer to use nextjs module styles always to avoid clustering this file!!!
*/

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, 'proxima-nova',
        -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
        Cantarell, Fira Sans, Droid Sans, sans-serif;
}

html {
    scroll-behavior: smooth;
}

a {
    color: inherit;
    text-decoration: none;
}

*,
*:after,
*:before {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

/* Next JS Boilerprate to be removed later */
.container {
    padding: 0 2rem;
    margin: 0 auto !important;
    min-height: 50vh;
    padding-top: 15%;
    text-align: center;
    border: 1px solid red;
}

.main {
    min-height: 100vh;
    padding: 4rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer {
    display: flex;
    flex: 1;
    padding: 2rem 0;
    border-top: 1px solid #eaeaea;
    justify-content: center;
    align-items: center;
}

.footer a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.title a {
    color: #0070f3;
    text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
    text-decoration: underline;
}

.title {
    margin: 0;
    line-height: 1.15;
    font-size: 4rem;
}

.title,
.description {
    text-align: center;
}

.description {
    margin: 4rem 0;
    line-height: 1.5;
    font-size: 1.5rem;
}

.code {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono,
        DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
}

.card {
    margin: 1rem;
    padding: 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    max-width: 300px;
}

.card:hover,
.card:focus,
.card:active {
    color: #0070f3;
    border-color: #0070f3;
}

.card h2 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

.card p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
}

.logo {
    height: 1em;
    margin-left: 0.5rem;
}

.booking-button {
    font-size: 21px;
    width: 250px;
    display: unset;
}

.button > svg:not(:last-child),
.button-lite > svg:not(:last-child) {
    margin-left: 0;
}

td.date-time-input-day-outside-of-month {
    visibility: hidden !important;
}

@media (max-width: 600px) {
    .button > .button-label-text,
    .button-lite > .button-label-text {
        overflow: unset !important;
        margin: 0;
    }
}

/* boilerprate end */

.text-21px {
    font-size: 21px !important;
}

@media (min-width: 1024px) {
    .text-22px {
        font-size: 22px !important;
    }

    .text-25px {
        font-size: 25px !important;
    }
}

::-webkit-input-placeholder {
    color: #777777 !important;
    text-align: left;
}

::-moz-placeholder {
    color: #777777 !important;
    text-align: left;
}

:-ms-input-placeholder {
    color: #777777 !important;
    text-align: left;
}

:-moz-placeholder {
    color: #777777 !important;
    text-align: left;
}

.leading-22px {
    line-height: 22px !important;
}

.text-secondary-1 {
    color: #212a45 !important;
}

.text-2-override {
    font-size: 15px !important;
}

.top-border {
    border-top: 1px solid #e0e0e0;
}

.pc-popover.shadow-floating {
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.5) !important;
}

.back-btn-wrapper svg,
.back-btn-wrapper .button-label-text {
    margin: 0 !important;
    color: #f34113;
}

.mt-11 {
    margin-top: 4rem !important;
}

.booking-bg-color-secondary {
    background-color: rgb(227, 244, 251) !important;
}

.button-secondary:not(.button-outlined) {
    background-color: #f34113 !important;
}

.main-nav-menu-horizontal .main-nav-menu-item-link {
    font-size: 1.05em !important;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.default-header .global-header-lite-main-bar-content {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
}

.default-header .main-nav-menu-horizontal .main-nav-menu-item > ul {
    min-width: 225px;
}

.default-header.two-markets-columns a#markets + ul {
    min-width: 350px;
}

.default-header.two-services-columns a#services + ul {
    min-width: 500px;
}

pre {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.text-heading-01,
.text-heading-02,
.text-heading-03,
.text-heading-04,
.text-heading-05,
.text-heading-06 {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.text-body-long,
.text-body-short,
.text-label,
.text-caption,
.text-helper-text {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.deprecated-button,
.button,
.button-lite {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.global-header-lite-main-bar-content {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.input-element,
.input-element::-moz-placeholder,
.input-element:-ms-input-placeholder,
.input-element::placeholder {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.link {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.list-medium,
.list-large {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.message {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.main-nav-menu-horizontal .main-nav-menu-item-link {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.main-nav-menu-vertical .main-nav-menu-item-link {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.main-nav-menu-horizontal .main-nav-menu-item > ul > li > a {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.progress-indicator-label,
.progress-indicator-value-label {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.text-area-root {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.input-element {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.input-area-element::-moz-placeholder,
.input-area-element:-ms-input-placeholder,
.input-area-element::placeholder {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.font-display,
.font-body,
.font-mono {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.sm\:font-display,
.sm\:font-body,
.sm\:font-mono {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.md\:font-display,
.md\:font-body,
.md\:font-mono {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.lg\:font-display,
.lg\:font-body,
.lg\:font-mono {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.xl\:font-display,
.xl\:font-body,
.xl\:font-mono {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.\32xl\:font-display,
.\32xl\:font-body,
.\32xl\:font-mono {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.\33xl\:font-display,
.\33xl\:font-body,
.\33xl\:font-mono {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.text-white {
    font-family: 'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.text-heading-03,
.text-heading-04 {
    letter-spacing: -0.009rem;
}

.text-heading-03 {
    text-transform: uppercase;
    font-size: 1.75rem;
}

.benefit-tiles .rounded-full {
    background-color: #f34113 !important;
}

.global-footer-dark {
    background-color: #91877f;
}

#book-now-footer {
    background-color: #f34113;
}

.link-color-interactive {
    color: #008487;
}

.text-interactive {
    color: #008487;
}

.text-secondary {
    color: #f34113;
}

.text-gray {
    color: #91877f;
}

.default-header .main-nav-menu-item-link-label {
    color: #91877f;
}

.progress-indicator-interactive-bar {
    color: #008487;
}

.hover-interactive:hover {
    background-color: #ffecd1;
}

.bg-fd-lg {
    background-color: #f5f5f5;
}

.date-time-input-day-selected {
    background-color: #008487;
}

.date-time-input-day:hover {
    background-color: #abd3d8;
}

.pricing
    .main-nav-menu-vertical.main-nav-menu-primary
    .main-nav-menu-item-link-wrapper {
    color: white;
}

.proconnect-overlay {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 195px;
    min-height: 167px;
}

@media (min-width: 1024px) {
    .proconnect-overlay {
        width: 245px;
        min-height: 153px;
    }
}

.proconnect-overlay .progress-indicator {
    width: 100%;
}

.proconnect-overlay:not(.gray) .progress-indicator-primary-bar {
    color: #008487;
}

.proconnect-overlay .progress-indicator-label {
    padding-top: 0.4rem;
    color: #757575;
    font-weight: 700;
}

.legal-zip-wrapper .form-field-top-row {
    margin-bottom: 0;
}

.legal-btn-wrapper .button-interactive {
    background-color: #008487 !important;
}

.booking-terms .agreement-title {
    text-align: center;
    font-size: 10pt;
}

.booking-terms .last-updated {
    display: none;
}

.overlay.white-bg .overlay-backdrop {
    background-color: #fff;
}

.color-teal {
    color: #008487;
}

.color-black {
    color: #000000;
}

.color-black-1 {
    color: #282828;
}

.documents-table .table-mobile-card-row-key,
.documents-table .table-mobile-card-row-value {
    flex: 1 1 50%;
}

.modal-buttons .button-label-text {
    color: #91877f;
}

.modal-buttons .times-btn {
    width: 32px;
}

.modal-buttons > button:first-of-type {
    padding-left: 0;
}

.modal-buttons > button:first-of-type .button-label-text {
    margin-left: 0;
}

.book-complete-inner-overlay .cancel-prompts .text-label {
    font-size: 0.9rem;
}

.modal-ftdr,
.modal-ftdr * {
    font-family: 'Montserrat', sans-serif !important;
    font-optical-sizing: auto;
}

.booking-ftdr .input-root,
.booking-ftdr input,
.booking-ftdr .text-area-root {
    border-radius: 10px;
}

.booking-ftdr .date-time-input-date {
    background: transparent;
}

.booking-ftdr .booking-button {
    font-size: 1.13rem;
    width: unset;
    min-width: 160px;
    padding: 0.82rem;
}

.booking-footer-ftdr:not(.shows-back-btn) .booking-button {
    width: 100%;
}

.hiw-section-ftdr .text-heading-03,
.reviews-ftdr .text-heading-03,
.specials-ftdr .text-heading-03,
.services-we-cover-ftdr .text-heading-03 {
    color: #202945;
    font-size: 2rem;
    line-height: 45px;
    text-transform: capitalize;
}

.hiw-section-ftdr .text-heading-04,
.reviews-ftdr .text-heading-04 {
    line-height: 22px;
    color: #282828;
    font-size: 1rem;
}

.serviceswecover-list-ftdr li {
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.02em;
    margin-top: 0.5rem;
}

@media (min-width: 1024px) {
    .serviceswecover-list-ftdr li:first-child {
        margin-top: 0;
    }
}

.serviceswecover-list-ftdr path {
    fill: #028386;
}

.guarantee-ftdr .text-heading-04 {
    text-transform: capitalize;
}

.footer-ftdr .global-footer-dark {
    background: #fff;
}

.footer-ftdr #book-now-footer {
    padding-left: 15px;
    padding-right: 15px;
    padding: 0.82rem;
}

.footer-ftdr .global-footer-module {
    margin-right: 0;
}

.footer-ftdr .global-footer-copyright .text-helper-text {
    color: #282828;
    font-size: 0.71rem;
    line-height: 20px;
}

.footer-ftdr .global-footer-links a {
    color: #282828;
    font-weight: 500;
    font-size: 0.71rem;
    line-height: 12px;
    letter-spacing: 0px;
}

.footer-ftdr .footer-btn-wrapper button {
    background-color: #f34113;
    font-size: 1.13rem !important;
    padding: 0.82rem;
}

@media (min-width: 768px) {
    .booking-footer-ftdr {
        padding-left: 7.5rem;
        padding-right: 7.5rem;
    }

    .booking-footer-ftdr.shows-back-btn {
        padding-left: 3.2rem;
        padding-right: 3.2rem;
    }
}

.booking-footer-ftdr .back-btn-wrapper svg,
.booking-footer-ftdr .back-btn-wrapper .button-label-text {
    color: #282828;
}

.booking-footer-ftdr .back-btn-wrapper button {
    font-size: 1rem;
}

.booking-ftdr ::placeholder,
.booking-ftdr ::-webkit-input-placeholder,
.booking-ftdr ::-moz-placeholder,
.booking-ftdr :-ms-input-placeholder,
.booking-ftdr :-moz-placeholder {
    font-size: 0.88rem !important;
    color: #282828 !important;
    font-style: normal !important;
}

.booking-ftdr input,
.booking-ftdr textarea {
    font-size: 0.88rem !important;
    color: #282828 !important;
    font-style: normal !important;
}

.booking-ftdr .text-area-root {
    margin-top: 2.3rem;
}

.booking-ftdr .text-area-element {
    padding: 1rem 1.1rem;
    min-height: 138px;
}

.booking-ftdr .popover-anchor div,
.hiw-section-ftdr .popover-anchor div {
    font-size: 1em;
}

.header-ftdr .badge-ftdr {
    font-size: 1rem;
}

@media (min-width: 465px) {
    .apply-page .switch-group .switch-label {
        width: 310px;
    }
}

@media (min-width: 630px) {
    .apply-page .switch-group .switch-label {
        width: 480px;
    }
}

.cookie-consent-panel {
    background-color: #ede0d1;
    bottom: 0rem;
    position: fixed;
    border-radius: 0.75rem;
    width: 100%;
}
@media (min-width: 465px) {
    .cookie-consent-panel {
        right: 1rem;
        max-width: 24rem;
    }
}

/* Slide up from bottom animation from https://www.w3schools.com/w3css/4/w3.css */
.w3-animate-bottom {
    /* position: relative; */ /* the element we're trying to animate has fixed position */
    animation: animatebottom 0.8s;
}
@keyframes animatebottom {
    from {
        bottom: -300px;
        opacity: 0;
    }
    to {
        bottom: 0;
        opacity: 1;
    }
}
